import React from 'react';
import Layout from '../components/layout/layout';
import * as styles from '../components/blog.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Commentsimg from '../images/comments.svg';
import ProfileImg from '../images/profile.svg';
import Facebook from '../images/facebookBlue.png';
import Twitter from '../images/twitterBlue.png';
import Linkedin from '../images/linkedinBlue.png';
import MailBlue from '../images/mailBlue.png';
import { FacebookProvider, Comments } from 'react-facebook';
import FeaturedPosts from '../components/featuredPosts';
import CardsRowDivImg4 from '../images/blockchainFull.jpeg';

class BradenWinstheSnowball extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  
  render(){
    return(
      <Layout>
        <div id="fb-root"></div>
          <div className={styles.landingSection}>
            <div className={styles.landingSectionSubDiv}>
              <text className={styles.blogHeader}>Blockchain: Peer-to-Peer Betting Marketplace</text>
              <div className={styles.subtitle}>
                <div className={styles.profileCol2}>
                  <img className={styles.ProfileImg} src={ProfileImg} alt="landingimg" />
                  <text className={styles.profileTest}>By David A. Ealy, Ed.D.</text>
                  <p>03 Sep, 2018</p>
                </div>
                <div className={styles.profileCol2} id={styles.profilecolId}>
                  <img className={styles.ProfileImg} src={Commentsimg} alt="landingimg" />
                  <text className={styles.profileTest}>0 Comments</text>
                </div>
              </div>
              <img className={styles.blogImg} src={CardsRowDivImg4} alt="landingimg" />
              <text className={styles.blogDesc}>
                <p>Can blockchain technology be used for gambling? Of course, yes. In fact, we’ve seen a great deal of movement towards blockchain over the past year for online casino games. There are many variations on the theme and application for casino-based games.</p>

                <p>Our focus is on the non-casino and non-fantasy applications, or what we call peer-to-peer prop betting.</p>

                <p>We use “prop bet” because a <text>prop bet</text> is binary, or there’s a definite winner and loser, and it allows for the prop to be settled at different times in an event.  This allow us to re-purpose the props for our other features like open contests and prop parties – where a leaderboard is involved that can be updated throughout the event (e.g., 1st quarter updates then ranks users, then halftime updates re-rank the users, etc.).</p>

                <p>We use “marketplace” because we’re innovating into a new peer-to-peer model.   The concept is simple – I should be able to bet anyone on anything at any time. Now, how do we do that? We create a one-stop shop that’s easy to use and allows a user to place a prop bet in the market for others to shop for a find.  </p>

                <text>Here’s the set-up –</text>

                <p>Alex is home and preparing to watch a basketball game. He wants to bet during the game and has interest in a specific proposition bet (LeBron James will NOT score 24pt or more) as he believes in the opposing team’s defensive strategy.   Alex creates the proposition bet using PropMe and places a $10 value on the bet.   He then places it into the Prop <text>Marketplace</text> on the ProGame app.</p>
                <div className={styles.subblogDesc}>
                  <text>Is this really a new concept?</text>
                </div>
                <p>Not 100%. But, the idea of peer-to-peer betting in a marketplace <text>without the involvement</text> of a sportsbook creating the prop or setting lines, validating the prop or bet and a bank managing the payment transaction is new.   The people create props. Your challenger will validate the prop (review and accept) and we’ll have a blockchain solution for the rest of the transaction, which is new.</p>
                <div className={styles.subblogDesc}>
                  <text>What are the challenges?</text>
                </div>

                <p><text>Legality.</text>  Gambling in the United States is still illegal, see this article which does a good job explaining the moving parts. Based on the current trend, we see this issue resolving this year. The worst-case scenario on the legality concern would be that our operations are limited to a certain number of states. However, because gambling is legal in other countries, we can pivot and accommodate a global audience.</p>

                <p><text>Determining who won.</text> There are a few large issues to we’re working through including completing the prop. Or, once the game or event has happened, how does this system determine who won? Our current feature called PropMe, image below, has a single step user-validation method.</p>

                <p>We’re adding on to this functionality to include a multiple step validation, as well as a governance model for disputes.</p>

                <p><text>Navigation.</text> Because the volume of users we’re expecting is high, we have a simple way to organize the props, once validated by the marketplace. We explain this organization method like this – when you go to a mall, all the vendors in the mall don’t have their products in one big pile. Rather, the shopper knows where in the mall to go to buy shoes, or the location of a specific store. Same idea of our props. Our initial offering of the marketplace will have virtual storefronts, if you will. If you want NFL games, then you go to the NFL store. If you want NFL games and Sunday afternoon games, then go that section of the store.</p>

                <p><text>Organization.</text> Because we encourage our users to prop often, we created a very simple way to track a prop’s status. Our current feature PropMe, screen below, which was designed to facilitate peer-to-peer prop betting that’s not in a public marketplace, we have a system that can be adopted for the <text>prop marketplace</text>. Our Active, Sent and Prop Track components will be easily re-purposed to accommodate.</p>
                <div className={styles.subblogDesc}>
                  <text>Why will this idea work?</text>
                </div>

                <p><text>Competition.</text>  There’s always someone who is willing to play or challenge your prop.  Team loyalty and pride runs deep in all cultures, so offering an outlet to challenge your opponent for the day is an easy sell.</p>

                <p><text>Props are easy to understand.</text>  One drawback to the early daily fantasy sports applications is that you needed a depth of understanding in the sport. Everyone can play using props. It’s easy to create and accept a prop.</p>

                <p><text>In-game betting.</text>  PropMe and the prop marketplace is perfect for in-game betting. What if you see a trend in the game, it’s easy to quickly create a prop and place it in the store. With all the location-based services available, why couldn’t we identify users that are attending the same game? This is on our roadmap and this technology is ubiquitous.</p>

                <p><text>There’s always an event.</text> This isn’t dependent on one season, one sport or one event. We’ve already started working through an entertainment and political offering…both are further down the roadmap.</p>
                <div className={styles.subblogDesc}>
                  <text>Next steps?</text>
                </div>

                <p>We’re very excited about the application of smart contracts and how the blockchain will facilitate our ability to offer a secure marketplace, scale and keep a history (see our previous blog post on this topic).</p>

                <p>We are working on a “commercial” application of this marketplace that would allow small restaurants and/or bars to offer props to their patrons, run promotions, do custom marketing and promotions, etc.</p>

                <p>We have many challenges ahead, but I bet you that empowering the individual and offering more freedom to game is a winning proposition.</p>
              </text>
              <div className={styles.oldPostsLink}>
                <a className={styles.servicelinks} href="/fornite-fever"><text>{'<'}Older Post</text></a>
              </div>
              <div className={styles.BlogsocialLinksDiv}>
                <img className={styles.social} src={Facebook} alt="landingimg" />
                <img className={styles.social} src={Twitter} alt="landingimg" />
                <img className={styles.social} src={Linkedin} alt="landingimg" />
                <img className={styles.social} src={MailBlue} alt="landingimg" />
              </div>
              <iframe name="f297624ad60196" width="500px" height="100px" data-testid="fb:comments Facebook Social Plugin" title="fb:comments Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.0/plugins/comments.php?app_id=691895914788460&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df247eedd96682d4%26domain%3Dfriendly-curran-11b640.netlify.app%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ffriendly-curran-11b640.netlify.app%252Ffe72a2fa7cef6c%26relation%3Dparent.parent&amp;color_scheme=light&amp;container_width=714&amp;height=100&amp;href=https%3A%2F%2Fwww.facebook.com%2Fbla%2F&amp;locale=en_US&amp;numposts=6&amp;sdk=joey&amp;version=v2.0&amp;width=500" style={{border: 'none', visibility: 'visible', width: '500px', height: '458px'}} class=""></iframe>
              <div className={styles.featuredNewsDiv}>
                <text>Featured News</text>
              </div>
              </div>
            </div>
          <div>
          <FeaturedPosts/>
        </div>
      </Layout>
    )
  }
}

export default BradenWinstheSnowball